.custom-quill .ql-container {
     height: 150px;  
    /* Set container height */
    border: 1px solid #ccc;
    /* border-radius: 4px; */
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset; */
}

.custom-quill .ql-toolbar {
    background-color: #f7f7f7;
    border: 1px solid #ccc;
    /* border-radius: 4px; */
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
}
  
.custom-quill .ql-editor {
    height: 150px; 
    /* Set editor content height */
    overflow-y: auto; /* Enable scrolling if content overflows */
}